import { FunctionComponent, ReactNode } from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import { createGlobalStyle } from 'styled-components';
import { Container, styled } from '@mui/material';
import { Header } from './header';

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export const Layout: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <>
      <BodyStyle />
      <Header />
      <Container
        maxWidth="xl"
        style={{
          height: '100%',
        }}
      >
        <Offset />
        {children}
      </Container>
    </>
  );
};

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const BodyStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;
