export enum EStorageKey {
  /**
   * Stores the current auth token
   */
  AUTH_TOKEN = 'mapped:auth_token',
  /**
   * Stores the current auth token
   */
  REFRESH_TOKEN = 'mapped:refresh_token',
}
