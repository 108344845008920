import { useContext } from 'react';
import { UserContext } from '../contexts/user';
import { Permission } from '@mapped/schema-graph-react-apollo';

export function usePermissions(perms: Permission[]) {
  const { permissions } = useContext(UserContext);

  const matchedPermissions = permissions.filter((p) =>
    perms.map(normalizePermission).includes(normalizePermission(p))
  );

  const hasPermission = !!matchedPermissions.length;

  return hasPermission;
}

export function normalizePermission(str: string) {
  return str.toUpperCase().replace(/[^a-zA-Z0-9]/g, '_');
}
