import { FunctionComponent } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export const QueryContextProvider: FunctionComponent<any> = ({ children }) => {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
