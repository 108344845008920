import { merge } from 'lodash';

export const Services = {
  auth: {
    client_id: process.env.AUTH_CLIENT_ID ?? '',
    client_secret: process.env.AUTH_CLIENT_SECRET ?? '',
    scope: process.env.AUTH_SCOPE ?? '',
    login_url: process.env.AUTH_LOGIN_URL ?? '',
    token_url: process.env.AUTH_TOKEN_URL ?? '',
    redirect_uri: process.env.AUTH_REDIRECT_URI ?? '',
    console_auth0_domain: process.env.CONSOLE_AUTH0_DOMAIN ?? '',
    console_auth0_client_id: process.env.CONSOLE_AUTH0_CLIENT_ID ?? '',
    console_auth0_client_secret: process.env.CONSOLE_AUTH0_CLIENT_SECRET ?? '',
    console_auth0_audience: 'https://api.mapped.com/graphql',
    console_mastodon_token_url: process.env.CONSOLE_MASTODON_TOKEN_URL ?? '',
    console_mastodon_client_id: process.env.CONSOLE_MASTODON_CLIENT_ID ?? '',
    console_mastodon_client_secret: process.env.CONSOLE_MASTODON_CLIENT_SECRET ?? '',
  },

  grpc_services: {
    anaxi: process.env.ANAXI_GRPC_SERVICE_ENDPOINT ?? '',
  },
};

export async function loadClientServices(): Promise<void> {
  if (!process.browser) {
    return;
  }

  const res = await fetch('/api/client-services');
  const clientServices = await res.json();

  merge(Services, clientServices);
}
