import { BuildingServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/building/building';
import { EqualEntitiesServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/equalentities/equalentities';
import { InferredIdentityServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/inferredidentities/inferredidentities';
import { InferredMetadataServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/inferredmetadata/inferredmetadata';
import { InferredRelationServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/inferredrelations/inferredrelations';
import { LabeledMetadataServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/labeledmetadata/labeledmetadata';
import { LabelingRulesServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/labelingrules/labelingrules';
import { LabelsServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/labels/labels';
import { ModelServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/models/models';
import { OrgServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/orgs/orgs';
import { RawMetadataServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/rawmetadata/rawmetadata';
import { ReviewLabeledMetadataServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/reviewlabeledmetadata/reviewlabeledmetadata';
import { WorkflowServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/workflow/workflow';
import { DatapanelServiceClientImpl } from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/workspace/datapanel/datapanel';
import {
  GrpcWebImpl,
  WorkspaceServiceClientImpl,
} from '@mapped/proto-browser/dist/mapped/cloud/api/anaxi/workspace/workspace';
import { BrowserHeaders } from 'browser-headers';
import { getSessionAuthToken } from '../auth/auth';
import { Services } from '../services';

function rpc() {
  return new GrpcWebImpl(Services.grpc_services.anaxi, {
    metadata: new BrowserHeaders({
      Authorization: `Bearer ${getSessionAuthToken()}`,
    }),
  });
}

function Workspace() {
  return new WorkspaceServiceClientImpl(rpc());
}

function Building() {
  return new BuildingServiceClientImpl(rpc());
}

function Org() {
  return new OrgServiceClientImpl(rpc());
}

function Workflow() {
  return new WorkflowServiceClientImpl(rpc());
}

function Model() {
  return new ModelServiceClientImpl(rpc());
}

function LabeledMetadata() {
  return new LabeledMetadataServiceClientImpl(rpc());
}

function RawMetadata() {
  return new RawMetadataServiceClientImpl(rpc());
}

function Labels() {
  return new LabelsServiceClientImpl(rpc());
}

function InferredMetadata() {
  return new InferredMetadataServiceClientImpl(rpc());
}

function InferredIdentity() {
  return new InferredIdentityServiceClientImpl(rpc());
}

function InferredRelation() {
  return new InferredRelationServiceClientImpl(rpc());
}

function EqualEntities() {
  return new EqualEntitiesServiceClientImpl(rpc());
}

function LabelingRules() {
  return new LabelingRulesServiceClientImpl(rpc());
}

function DataPanel() {
  return new DatapanelServiceClientImpl(rpc());
}

function ReviewLabeledMetadata() {
  return new ReviewLabeledMetadataServiceClientImpl(rpc());
}

export default {
  Workspace,
  Building,
  Org,
  Workflow,
  Model,
  Labels,
  LabeledMetadata,
  RawMetadata,
  InferredMetadata,
  InferredIdentity,
  InferredRelation,
  LabelingRules,
  DataPanel,
  EqualEntities,
  ReviewLabeledMetadata,
};
