import { Org } from '@mapped/proto-browser/dist/mapped/cloud/api/orgs/models';
import { MenuItem, Select } from '@mui/material';
import { FunctionComponent, useContext, useState } from 'react';
import { UserContext } from '../../contexts/user';
import { useOrganizations } from '../../hooks/useOrganizations';

export const OrgsSelect: FunctionComponent<IOrgsSelect> = ({
  onChange,
  name,
  label,
  required,
  disabled,
  defaultValue,
}) => {
  const { user } = useContext(UserContext);

  const [selectedOrgId, setSelectedOrgId] = useState(
    defaultValue ?? user.orgId ?? ''
  );

  const { orgs, isLoading, getOrgById } = useOrganizations({});

  if (!orgs.length) {
    return null;
  }

  return (
    <>
      <Select
        className="orgs-select"
        name={name}
        required={required}
        label={label}
        value={selectedOrgId}
        disabled={disabled || isLoading}
        onChange={(e) => {
          setSelectedOrgId(e.target.value);
          onChange?.(getOrgById(e.target.value));
        }}
      >
        {orgs.map((org) => (
          <MenuItem key={org.id} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

interface IOrgsSelect {
  name: string;
  label: string;
  onChange?: (org?: Org) => void;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
}
