import React from 'react';
import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';

export function LoadingButton(props: ButtonProps & { isLoading?: boolean }) {
  const { isLoading, disabled, ...rest } = props;
  return (
    <Button {...rest} disabled={disabled || isLoading}>
      <Box sx={{ position: 'relative', opacity: isLoading ? 0 : 1 }}>
        {props.children}
      </Box>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
    </Button>
  );
}
