import {
  GrpcWebImpl,
  OrgServiceClientImpl,
} from '@mapped/proto-browser/dist/mapped/cloud/api/orgs/orgs';
import {
  SignupServiceClientImpl,
} from '@mapped/proto-browser/dist/mapped/cloud/api/orgs/signup/signup';
import { BrowserHeaders } from 'browser-headers';
import { getSessionAuthToken } from '../auth/auth';

import { Services } from '../services';

function AllOrgs() {
  return new OrgServiceClientImpl(
    new GrpcWebImpl(Services.grpc_services.anaxi, {
      metadata: new BrowserHeaders({
        Authorization: `Bearer ${getSessionAuthToken()}`,
      }),
    })
  );
}

function CustomerOrgs() {
  return new SignupServiceClientImpl(
    new GrpcWebImpl(Services.grpc_services.anaxi, {
      metadata: new BrowserHeaders({
        Authorization: `Bearer ${getSessionAuthToken()}`,
      }),
    })
  );
}

export default {
  AllOrgs,
  CustomerOrgs,
}
