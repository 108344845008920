import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { AppProviders } from '../contexts/providers';
import { Notifications } from '../components/notifications';
import { ModalOnStage } from '../contexts/modals';

import 'react-perfect-scrollbar/dist/css/styles.css';
import '../css/main.css';
import '../utils/setupDatagrid';
import { Layout } from '../components/layout';

const App: FunctionComponent<AppPropsWithLayout> = ({
  Component,
  pageProps,
}) => {
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  return (
    <AppProviders>
      {getLayout(<Component {...pageProps} />)}
      <Notifications />
      <ModalOnStage />
    </AppProviders>
  );
};

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default App;
