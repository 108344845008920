import { MetadataType } from '../components/anaxi/labeling/types';

export const RoutePaths = {
  Root: '/anaxi/workspaces',
  Workspace: (workspaceId: string) => `${RoutePaths.Root}/${workspaceId}`,
  WorkspaceUsers: (workspaceId: string) =>
    `${RoutePaths.Root}/${workspaceId}/users`,
  WorkspaceBuilding: (workspaceId: string, buildingId: string) =>
    `${RoutePaths.Root}/${workspaceId}/${buildingId}`,
  WorkspaceLabeling: (workspaceId: string, subpath?: MetadataType) =>
    [
      `${RoutePaths.Root}/${workspaceId}/labeling`,
      subpath ?? MetadataType.RawAndLabeled,
    ]
      .filter(Boolean)
      .join('/'),
};
