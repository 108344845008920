import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { CSSProperties, FunctionComponent } from 'react';

export const Loader: FunctionComponent<{ style?: CSSProperties }> = ({
  style,
}) => {
  return (
    <Container style={style}>
      <CircularProgress size={60} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh);
`;
