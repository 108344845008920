import { AccountCircle } from '@mui/icons-material';
import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import Link from 'next/link';
import { FunctionComponent, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  exchangeToken,
  setAuthToken,
  setRefreshToken,
  setSessionAuthToken,
  setSessionRefreshToken,
} from '../../auth/auth';
import { RoutePaths } from '../../constants/RouterPaths';
import { NotificationsContext } from '../../contexts/notifications';
import { UserContext } from '../../contexts/user';
import { copyStringToClipboard } from '../../utils/mapped';
import { OrgsSelect } from '../organization/orgsSelect';

export const Header: FunctionComponent = () => {
  const { user, authToken } = useContext(UserContext);
  const { showNotification } = useContext(NotificationsContext);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLElement>();
  const [isSwitchingOrg, setIsSwitchingOrg] = useState(false);

  return (
    <AppBar position="fixed" data-testid="header" color="secondary">
      <Container maxWidth="xl">
        <Wrapper>
          <Toolbar style={{ padding: 0 }}>
            <Link href={RoutePaths.Root}>
              <Logo src="/img/logo.svg" />
            </Link>

            <Link href={RoutePaths.Root}>
              <Button color="primary">Anaxi</Button>
            </Link>

            <div style={{ flex: 1 }} />

            {user?.orgId && (
              <OrgsSelect
                name=""
                label=""
                disabled={isSwitchingOrg}
                onChange={async (org) => {
                  setIsSwitchingOrg(true);

                  const { access_token, refresh_token } = await exchangeToken(
                    authToken!,
                    org?.id!
                  );

                  setSessionAuthToken(access_token!);
                  setSessionRefreshToken(refresh_token!);
                  setAuthToken(access_token!);
                  setRefreshToken(refresh_token!);
                  window.location.href = '/anaxi/workspaces';
                }}
              />
            )}

            <div>
              <IconButton
                data-testid="user-menu"
                color="inherit"
                disabled={isSwitchingOrg}
                onClick={(e) => {
                  setUserMenuAnchor(e.currentTarget);
                  setIsUserMenuOpen(true);
                }}
              >
                {isSwitchingOrg ? (
                  <CircularProgress size="21px" />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>

              <Menu
                anchorEl={userMenuAnchor}
                keepMounted={true}
                open={isUserMenuOpen}
                onClose={() => setIsUserMenuOpen(false)}
              >
                <ListItem disabled={true}>{user?.name}</ListItem>

                <MenuItem
                  onClick={() => {
                    copyStringToClipboard(authToken!);
                    setIsUserMenuOpen(false);
                    showNotification({
                      type: 'success',
                      message: 'JWT copied to clipboard',
                    });
                  }}
                >
                  Copy JWT
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setIsUserMenuOpen(false);
                    window.location.href = '/api/logout';
                  }}
                >
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </Wrapper>
      </Container>
    </AppBar>
  );
};

const Logo = styled.img`
  height: 36px;
  margin-right: 32px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  .MuiButton-root {
    margin-right: 16px !important;
  }

  .MuiButton-textPrimary {
    background-color: rgba(81, 192, 172, 0.04);
  }

  .orgs-select {
    margin-right: 20px;
  }
`;
