import { Alert } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { NotificationsContext } from '../../contexts/notifications';

export const Notifications: FunctionComponent = () => {
  const { notification } = useContext(NotificationsContext);

  if (!notification) {
    return null;
  }

  return (
    <Container>
      <Alert severity={notification.type}>{notification.message}</Alert>
    </Container>
  );
};

const slideAnimation = keyframes`
  0% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 8px;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  animation-duration: 300ms;
  animation-timing-function: ease;
  animation-name: ${slideAnimation};
`;
