import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { FunctionComponent, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';
import { Loader } from '../components/layout/loader';
import { loadClientServices } from '../services';
import { getTheme } from '../theme/getTheme';
import { ModalsContextProvider } from './modals';
import { NotificationsContextProvider } from './notifications';
import { QueryContextProvider } from './query';
import { UserContextProvider } from './user';

export const AppProviders: FunctionComponent<any> = ({ children }) => {
  const theme = getTheme();

  return (
    <CookiesProvider>
      <ServicesProvider>
        <UserContextProvider>
          <QueryContextProvider>
            <ThemeProvider theme={theme}>
              <MUIThemeProvider theme={theme}>
                <NotificationsContextProvider>
                  <ModalsContextProvider>{children}</ModalsContextProvider>
                </NotificationsContextProvider>
              </MUIThemeProvider>
            </ThemeProvider>
          </QueryContextProvider>
        </UserContextProvider>
      </ServicesProvider>
    </CookiesProvider>
  );
};

const ServicesProvider: FunctionComponent<any> = ({ children }) => {
  const [isLoadingServices, setIsLoadingServices] = useState(true);

  useEffect(() => {
    loadClientServices().then(() => setIsLoadingServices(false));
  }, []);

  if (isLoadingServices) {
    return <Loader />;
  }

  return <>{children}</>;
};
