import anaxi from '../api/anaxi';
import { getSessionAuthToken } from '../auth/auth';

export function copyStringToClipboard(str: string) {
  navigator?.clipboard?.writeText?.(str);
}

function createMappedUtils() {
  if (!process.browser) {
    return;
  }

  (window as any)['mapped'] = {
    jwt: () => {
      return getSessionAuthToken()
    },

    grpc: {
      anaxi: {
        workspace: anaxi.Workspace,
        building: anaxi.Building,
        org: anaxi.Org,
        workflow: anaxi.Workflow,
        model: anaxi.Model,
        labeledMetadata: anaxi.LabeledMetadata,
        labels: anaxi.Labels,
        inferredMetadata: anaxi.InferredMetadata,
        labelingRules: anaxi.LabelingRules,
        datapanel: anaxi.DataPanel,
      },
    },
  };
}

createMappedUtils();
