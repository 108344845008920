import { DeepPartial } from '@mapped/proto-browser/dist/google/protobuf/struct';
import { ListRequest } from '@mapped/proto-browser/dist/mapped/cloud/api/orgs/orgs';
import { clone } from 'lodash';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import api from '../api/orgs';
import { UserContext } from '../contexts/user';

export function useOrganizations(params: IParams) {
  const { user } = useContext(UserContext);

  const { data, error, isLoading } = useQuery(
    ['ORGS'],
    () => {
      if (user?.orgId) {
        return api.CustomerOrgs().List({ ...params, userId: '' })
      }

      return api.AllOrgs().List({ ...params, includeDeleted: false })
    },
    { refetchOnMount: false }
  );

  const orgs = clone(data?.orgs)?.reverse() ?? [];

  function getOrgById(id: string) {
    return orgs.find((o) => o.id === id);
  }

  return {
    orgs,
    isLoading,
    error,
    getOrgById,
  };
}

interface IParams extends DeepPartial<ListRequest> { }
