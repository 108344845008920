import { Permission } from '@mapped/schema-graph-react-apollo';
import { WebAuth } from 'auth0-js';
import axios from 'axios';
import jwt from 'jwt-simple';
import qs from 'query-string';
import { EAuthProvider } from '../components/auth/providerButton';
import { normalizePermission } from '../hooks/usePermissions';
import { Services } from '../services';
import { EStorageKey } from '../types/general';

export function getAzureADSignInURL(options?: {
  redirect_uri?: string;
  response_type?: string;
  state?: string;
}) {
  const { auth } = Services;
  const redirect_uri = options?.redirect_uri || auth.redirect_uri;
  const response_type = options?.response_type || 'code';
  const state = options?.state || '';

  return `${auth.login_url}?client_id=${auth.client_id}&redirect_uri=${redirect_uri}&state=${state}&response_type=${response_type}&scope=${auth.scope}`;
}

function getWebAuth(): WebAuth {
  const client = require('auth0-js')
  const { auth } = Services

  return new client.WebAuth({
    domain: auth.console_auth0_domain,
    clientID: auth.console_auth0_client_id,
    redirectUri: auth.redirect_uri,
    audience: auth.console_auth0_audience,
    scope: auth.scope,
    responseType: 'code',
  })
}

export function authorizeWithCredentials({
  email,
  password,
  state = 'auth0',
}: {
  email: string
  password: string
  state?: string
}): Promise<{ error?: string }> {
  const login = (): Promise<any> =>
    new Promise((resolve) => {
      getWebAuth().login(
        {
          email,
          password,
          realm: 'global',
          state,
          scope: Services.auth.scope,
        },
        (err) => {
          resolve({ error: err?.description })
        }
      )
    })

  return login()
}

export function authorizeWithSocial(
  provider: EAuthProvider,
  { state = 'auth0', connection }: { state?: string; connection?: string }
) {
  switch (provider) {
    case EAuthProvider.GOOGLE:
      return getWebAuth().authorize({ connection: 'google-oauth2', state })
    case EAuthProvider.MICROSOFT:
      return getWebAuth().authorize({ connection: 'windowslive', state })
    case EAuthProvider.GITHUB:
      return getWebAuth().authorize({ connection: 'github', state })
    case EAuthProvider.SSO:
      return getWebAuth().authorize({ connection, state })
  }
}

export async function fetchLoggedUser() {
  try {
    const authToken = getSessionAuthToken();
    const token = decodeJWT(authToken!);

    const user = {
      id: token['sub'],
      name: token['https://mapped.com/email'],
      email: token['https://mapped.com/email'],
      orgId: token['https://mapped.com/org'],
    };

    const permissions: Permission[] =
      token?.permissions?.map?.(normalizePermission) || [];

    return { authToken, user, permissions };
  } catch (e) {
    return null;
  }
}

export function decodeJWT(token: string): any {
  try {
    return jwt.decode(token!, '', true) ?? {};
  } catch (e) {
    return {}
  }
}

export function setAuthToken(token: string) {
  localStorage.setItem(EStorageKey.AUTH_TOKEN, token);
}

export function setSessionAuthToken(token: string) {
  sessionStorage.setItem(EStorageKey.AUTH_TOKEN, token);
}

export function getAuthToken() {
  return localStorage.getItem(EStorageKey.AUTH_TOKEN);
}

export function getSessionAuthToken() {
  let token = sessionStorage.getItem(EStorageKey.AUTH_TOKEN);
  return token || getSessionRefreshToken() ? token : getAuthToken();
}

export function setRefreshToken(token: string) {
  localStorage.setItem(EStorageKey.REFRESH_TOKEN, token);
}

export function setSessionRefreshToken(token: string) {
  sessionStorage.setItem(EStorageKey.REFRESH_TOKEN, token);
}

export function getRefreshToken() {
  return localStorage.getItem(EStorageKey.REFRESH_TOKEN);
}

export function getSessionRefreshToken() {
  return sessionStorage.getItem(EStorageKey.REFRESH_TOKEN);
}

export function isTokenAboutToExpire(t?: string) {
  try {
    const token = jwt.decode(t ?? '', '', true) ?? {};
    const exp = Number(token.exp);
    const threshold = 300; // 5 minutes

    if (!!exp && exp - threshold <= Date.now() / 1000) {
      return true;
    }

    return false;
  } catch (e) {
    return true;
  }
}

export async function tradeCodeForTokens(
  code: string,
  auth0?: boolean
): Promise<IOAuthTokenResponse> {
  try {
    const { data } = await axios.post<IOAuthTokenResponse>(
      `/api/issueTokens`,
      qs.stringify({ code, auth0 })
    );

    return data;
  } catch (e) {
    return {};
  }
}

export async function exchangeToken(subject_token: string, org_id?: string) {
  const res = await axios.post('/api/exchangeToken', {
    subject_token,
    org_id
  })

  if (res?.data?.error) {
    console.info('[exchangeToken fail]', res?.data?.stack)
  }

  return res.data as {
    error?: string
    access_token?: string
    refresh_token?: string
    expires_in?: number
  }
}

export async function renewAuthToken() {
  try {
    if (!isTokenAboutToExpire(getAuthToken()!)) {
      return true;
    }

    if (!getRefreshToken()) {
      return false;
    }

    const { data } = await axios.post<IOAuthTokenResponse>(
      `/api/refreshToken`,

      qs.stringify({
        refresh_token: getRefreshToken(),
      })
    );

    setAuthToken(data.access_token!);
    setRefreshToken(data.refresh_token!);

    return true;
  } catch (e) {
    localStorage.removeItem(EStorageKey.AUTH_TOKEN);
    localStorage.removeItem(EStorageKey.REFRESH_TOKEN);
    return false;
  }
}

export async function renewSessionAuthToken() {
  try {
    if (!isTokenAboutToExpire(getSessionAuthToken()!)) {
      return true;
    }

    if (!getSessionRefreshToken()) {
      return false;
    }

    const { data } = await axios.post<IOAuthTokenResponse>(
      `/api/refreshToken`,

      qs.stringify({
        refresh_token: getSessionRefreshToken(),
      })
    );

    setSessionAuthToken(data.access_token!);
    setSessionRefreshToken(data.refresh_token!);

    return true;
  } catch (e) {
    sessionStorage.removeItem(EStorageKey.AUTH_TOKEN);
    sessionStorage.removeItem(EStorageKey.REFRESH_TOKEN);
    return false;
  }
}

export interface IOAuthTokenResponse {
  id_token?: string;
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
}
