import { createContext, FunctionComponent, useState } from 'react';
import { ICustomModal } from '../components/customModal';
import { useModals } from '../hooks/useModals';

export const ModalsContext = createContext<IModalsContextValue>(
  {} as IModalsContextValue
);

export const ModalsContextProvider: FunctionComponent<any> = ({ children }) => {
  const [modal, setModal] = useState<IModal>();

  function open(Component: any, props: any) {
    setModal({
      Component,

      props: {
        ...props,

        open: true,

        onClose: () => {
          props?.onClose && props?.onClose();
          setModal(undefined);
        },
      },
    });
  }

  function close() {
    setModal(undefined);
  }

  const state = {
    modal,
    open: open as TOpenModalMethod,
    close,
  };

  return (
    <ModalsContext.Provider value={state}>{children}</ModalsContext.Provider>
  );
};

export const ModalOnStage: FunctionComponent = () => {
  const { modal } = useModals();

  if (!modal) {
    return null;
  }

  const { Component, props } = modal;

  return <Component {...props} />;
};

interface IModal {
  Component: FunctionComponent<ICustomModal & any>;
  props: ICustomModal & any;
}

interface IModalsContextValue {
  modal?: IModal;
  open: TOpenModalMethod;
  close: () => void;
}

// @ts-ignore
type TModalProps<T> = Omit<Parameters<T>[0], 'open'>;

type TOpenModalMethod = <C = FunctionComponent<{}>>(
  Component: C,
  props?: TModalProps<C>
) => void;
