import { Permission } from '@mapped/schema-graph-react-apollo';
import { createContext, FunctionComponent, useEffect, useState } from 'react';
import useInterval from 'use-interval';
import * as auth from '../auth/auth';
import { Loader } from '../components/layout/loader';

export const UserContext = createContext<IUserContextValue>(
  {} as IUserContextValue
);

export const UserContextProvider: FunctionComponent<any> = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState<
    ILoggedUser | undefined | null
  >();

  async function fetchUser() {
    setLoggedUser((await auth.fetchLoggedUser()) as ILoggedUser | null);
  }

  useEffect(() => {
    auth.renewAuthToken().then(() => {
      auth.renewSessionAuthToken().then(fetchUser);
    });
  }, []);

  useInterval(() => {
    auth.renewAuthToken().then(auth.renewSessionAuthToken);
  }, 30000);

  if (loggedUser === undefined) {
    return <Loader />;
  }

  return (
    <UserContext.Provider
      value={{
        authToken: loggedUser?.authToken,
        user: loggedUser?.user as any,
        permissions: loggedUser?.permissions || [],
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

interface IUserContextValue {
  authToken: string | undefined;
  permissions: Permission[];
  user: {
    id: string;
    email: string;
    name: string;
    orgId?: string;
  };
}

export interface ILoggedUser {
  authToken: string;
  permissions: Permission[];
  user: {
    id: string;
    email: string;
    name: string;
    orgId?: string;
  };
}
