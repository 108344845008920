import { ButtonProps, Tooltip } from '@mui/material';
import { styled } from '@mui/styles';
import { LoadingButton } from '../ui/loadingButton';

const AuthButton = styled(LoadingButton)(() => ({}));

export enum EAuthProvider {
  GOOGLE = 'Google',
  MICROSOFT = 'Microsoft',
  GITHUB = 'Github',
  EMAIL = 'email',
  SSO = 'SSO',
}

interface IButtonProps extends ButtonProps {
  prefix: string;
  isLoading?: boolean;
}

export const AuthProviderButtonsMap = {
  [EAuthProvider.GOOGLE]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton size="medium" variant="outlined" {...props}>
      <span>{prefix} Google</span>
    </AuthButton>
  ),

  [EAuthProvider.MICROSOFT]: ({ prefix, ...props }: IButtonProps) => (
    <Tooltip
      placement="right"
      arrow={true}
      title="Only social Microsoft accounts can be used. For work/school accounts please use the regular email option."
    >
      <AuthButton size="medium" variant="outlined" {...props}>
        <span>{prefix} Microsoft</span>
      </AuthButton>
    </Tooltip>
  ),

  [EAuthProvider.GITHUB]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton {...props} size="medium" variant="outlined">
      <span>{prefix} Github</span>
    </AuthButton>
  ),

  [EAuthProvider.SSO]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton size="medium" variant="outlined" {...props}>
      <span>{prefix} SSO</span>
    </AuthButton>
  ),

  [EAuthProvider.EMAIL]: ({ prefix, ...props }: IButtonProps) => (
    <AuthButton size="medium" variant="outlined" {...props}>
      <span>{prefix} your email</span>
    </AuthButton>
  ),
};
