import { createTheme } from '@mui/material';

export function getTheme() {
  return createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
    palette: {
      background: {
        default: '#191F2C',
        paper: '#363e4d',
      },

      text: {
        primary: '#ffffff',
        secondary: '#ffffff',
      },

      action: {
        active: '#ffffff',
      },

      info: {
        main: '#fff',
        contrastText: '#000',
      },

      primary: {
        main: '#51c0ac',
        contrastText: '#ffffff',
      },

      secondary: {
        main: '#191F2C',
        contrastText: '#ffffff',
      },
    },
  });
}
